import { Button, Grid, Typography, useTheme } from "@mui/material";
import React from "react";

import sostieniciImg from "../assets/sostienici.jpg";
import { Helmet } from "react-helmet";
import CookieBanner from "../components/CookieBanner";
import { checkCookieExists } from "../utils/checkCookieExists";

export default function Amici() {
  const theme = useTheme();
  return (
    <Grid
      container
      direction={"column"}
      spacing={1}
      pt={2}
      pr={20}
      pl={20}
      width={"100%"}
    >
      <CookieBanner initialOpen={!checkCookieExists("cookieConsent")} />
      <Helmet>
        <meta
          name="description"
          content="Sostieni CERtrude per creare le tue Comunità Energetiche Rinnovabili CER"
        />
      </Helmet>
      <h1 className="text-center blue-light-color big-title">
        Gli amici di CERtrude
      </h1>
      <Grid container>
        <Grid item sm={12} md={6} lg={7}>
          <img src={sostieniciImg} width="100%" />
        </Grid>
        <Grid
          item
          sm={12}
          md={6}
          lg={5}
          pl={3}
          display="flex"
          direction="column"
          justifyContent="space-around"
        >
          <Grid item>
            <Typography variant="body1" className="blue-light-color">
              CERtrude è un progetto di Light s.r.l. che vuole diffondere
              conoscenza sulle energie rinnovabili e supportare la diffusione
              delle CER.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className="blue-light-color">
              Noi crediamo che le energie rinnovabili siano tra i nostri
              migliori alleati per prenderci cura del pianeta e costruire un
              futuro più pulito e sostenibile.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" className="blue-light-color">
              Le rinnovabili, agevolando l'autoproduzione diffusa, possono
              generare un impatto che va oltre la produzione di energia pulita:
              abilitano la condivisione, stimolando connessioni che consentano
              alle persone di crescere insieme, anche di non lasciare indietro
              nessuno. Una comunità che non si prende solo cura del luogo in cui
              vive, ma anche dei suoi membri, generando benefici per tutti.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container sm={12} spacing={2} mt={2} pl={10} pr={10}>
        <Grid item>
          <Typography variant="h6" className="blue-light-color">
            CERtrude si pone l'obiettivo di facilitare la nascita delle Comunità
            e siccome per noi è importante, una versione basilare ma completa è
            a disposizione di tutti, gratuitamente.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" className="blue-light-color">
            Tutto questo è possibile solo grazie agli amici di CERtrude, coloro
            che hanno creduto nel valore di questo progetto, supportandolo
            dall'inizio.
          </Typography>
        </Grid>
      </Grid>

      {/* 
      <Grid item mt={2} mb={3} p={1}>
        <Paper
          component={"div"}
          style={{
            padding: 12,
          }}
        >
          <Grid container direction={"column"}>
            <Grid item>
              <Typography
                variant="h4"
                align="center"
                className="blue-light-color"
              >
                Alcuni dei miei amici
              </Typography>
            </Grid>
            <Grid item textAlign={"center"}>
              Loghi
            </Grid>
          </Grid>
        </Paper>
      </Grid>
        */}

      <Grid container mb={4} mt={4} alignItems="center">
        <Grid item xs={6} display="flex" justifyContent="center">
          <Typography variant="h6" className="blue-light-color">
            Altre ragioni per supportare CERtrude:
            <ul>
              <li>Ricevere training</li>
              <li>Ottenere contenuti</li>
              <li>Esserci!</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="dark"
            sx={{
              height: 60,
              minWidth: 400,
              fontSize: 24,
              padding: 6,
              color: "#fff",
              "&:hover": { backgroundColor: theme.palette.dark.hover },
            }}
            href={
              "mailto:certrude@likelight.it?subject=Voglio sostenere CERtrude"
            }
          >
            Sostienila anche tu!
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
