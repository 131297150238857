export function checkCookieExists(name){
    // Split document.cookie string into individual cookies
    const cookies = document.cookie.split(";");
  
    // Loop through the cookies to find the one with the specified name
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if the cookie starts with the specified name
      if (cookie.startsWith(name + "=")) {
        // Cookie found, return true
        return true;
      }
    }
  
    // Cookie not found, return false
    return false;
}