import React, { useEffect, useRef, useState } from "react";
import jwt_decode from "jwt-decode";
import {
  Typography,
  Button,
  TextField,
  Card,
  Grid,
  Checkbox,
} from "@mui/material";

import { toast } from "react-toastify";

import StartIcon from "@mui/icons-material/Start";
import SendIcon from "@mui/icons-material/Send";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Helmet } from "react-helmet";
import CookieBanner from "../components/CookieBanner";
import { checkCookieExists } from "../utils/checkCookieExists";
import AmicoLoginModal from "../components/AmicoLoginModal";

import { getCookie, setCookie } from "../utils/manageCookies";

export default function Accedi() {
  const mail = useRef();
  const sessionCode = useRef();
  const [showCode, setShowCode] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);

  const [openAmicoLogin, setOpenAmicoLogin] = useState(false);
  const handleOpenModal = () => setOpenAmicoLogin(true);
  const handleCloseModal = () => setOpenAmicoLogin(false);

  useEffect(() => {
    //controlla se il client ha già un token valido
    const isToken = () => {
      const token = getCookie("token");
      if (token) {
        //check expiry date
        const { exp, sessionID } = jwt_decode(token);
        if (Date.now() < exp * 1000) {
          setShowAccess(true);
          setCookie("sessionID", sessionID);
        }
      }
    };

    isToken();
  }, []);

  const createNewSession = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/session/create`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      if (data.success) {
        setCookie("sessionID", data.sessionID);
        return data.sessionID;
      } else {
        toast.error(
          "Errore nella richiesta di creazione di una nuova sessione. Riprova più tardi."
        );
        /*console.log(
          `Errore nella richiesta di creazione di una nuova sessione: ${data.message}`
        );*/
      }
    } catch (e) {
      if (!e?.response) {
        toast.error("Il server non risponde. Prova più tardi");
        //console.log(`${e}: Il server non risponde. Prova più tardi`);
        //return true; //ritorna vero, come se esistesse già il SID
      }
    }
    return -1; //se sono qui c'è stato un errore
  };

  const handleRegister = async () => {
    const sessionID = await createNewSession();
    if (sessionID >= 0) {
      try {
        const response = await toast.promise(
          fetch(`${process.env.REACT_APP_API_URL}/api/register-tutor`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: mail.current.value, sessionID }),
          }),
          {
            pending: "Sto inviando il codice al tuo indirizzo email",
            success:
              "Codice sessione inviato correttamente. Controlla la tua mail",
            error: "Non è stato possibile inviare il codice. Riprova più tardi",
          }
        );

        const data = await response.json();
        if (data.success) {
          setShowCode(true);
        } else {
          //return false
          toast.error(
            "Non è stato possibile inviare il codice. Prova più tardi"
          );
        }
      } catch (e) {
        if (!e?.response) {
          toast.error("Il server non risponde. Prova più tardi");
          console.log(`${e}: Il server non risponde. Prova più tardi`);
          //return false;
        }
      }
    }
  };

  const handleLogin = async () => {
    try {
      const sessionID = getCookie("sessionID");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login-tutor`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: mail.current.value,
            sessionID,
            password: sessionCode.current.value,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        //console.log(data.token);
        setCookie("token", data.token);
        window.location.href = data.subdomain;
      } else {
        //return false
        toast.error("mail/codice non validi");
        console.log("ERRORE: mail/codice non validi");
      }
    } catch (e) {
      if (!e?.response) {
        toast.error("Il server non risponde. Prova più tardi");
        console.log(`${e}: Il server non risponde. Prova più tardi`);
        //return false;
      }
    }
  };

  const recoverSession = () => {
    const token = getCookie("token");
    const { sessionID, subdomain } = jwt_decode(token);
    setCookie("sessionID", sessionID);
    window.location.href = subdomain;
  };

  return (
    <Grid container spacing={2} sx={{ px: "4rem", py: "3rem" }}>
      <CookieBanner initialOpen={!checkCookieExists("cookieConsent")} />
      <Helmet>
        <meta
          name="description"
          content="CERtrude ti offre un simulatore gratuito per la Comunità Energetica Rinnovabile CER"
        />
      </Helmet>
      <Grid item md={6} container gap={2}>
        <h1
          style={{ fontSize: "2rem", fontWeight: 700, color: global.blueColor }}
        >
          Pochi semplici passi per cominciare:
        </h1>
        <p className="step-explanation">
          <span className="step-span">1</span> Riunisci i tuoi amici o i
          possibili membri (anche online) e usa il tuo computer (ancora meglio
          se collegato ad uno schermo o proiettore, soprattutto se siete in
          tanti!) per mostrare a tutti i contenuti della sessione
        </p>
        <p className="step-explanation">
          <span className="step-span">2</span> Scrivi qui il tuo indirizzo email
          e richiedi il codice per la tua sessione
        </p>
        <p className="step-explanation">
          <span className="step-span">3</span> Inserisci il codice e ... buon
          divertimento!
        </p>
      </Grid>
      <Grid item md={6} container justifyContent="center" alignItems="center">
        {showAccess ? (
          <>
            <Card sx={{ p: 2, width: "80%" }}>
              <Typography variant="h5" align="center" gutterBottom>
                E' già presente una sessione attiva
              </Typography>
              <Button
                onClick={recoverSession}
                variant="contained"
                color="primary"
                endIcon={<StartIcon />}
                fullWidth
              >
                Riprendi la sessione
              </Button>
            </Card>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              style={{ margin: 20 }}
            >
              oppure
            </Typography>
          </>
        ) : null}
        <Card sx={{ p: 2, width: "80%" }}>
          <Typography variant="h5" align="center" gutterBottom>
            Crea una nuova sessione
          </Typography>
          <TextField
            required
            id="input-mail"
            label="Email"
            inputRef={mail}
            fullWidth
            style={{ marginBottom: 10 }}
          />
          <Grid
            container
            justifyContent="flex-start"
            gap={2}
            mb={2}
            alignItems="center"
          >
            <Checkbox
              checked={checkedPrivacy}
              onChange={() => {
                setCheckedPrivacy(!checkedPrivacy);
              }}
            />
            <p>
              Ho letto e accetto la{" "}
              <a
                target="_blank"
                style={{
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "#222",
                }}
                href="/privacy_policy.html"
              >
                privacy policy
              </a>
            </p>
          </Grid>
          <Button
            onClick={handleRegister}
            variant="contained"
            color="primary"
            endIcon={<SendIcon />}
            fullWidth
            disabled={!checkedPrivacy}
          >
            Ricevi codice via mail
          </Button>
          {showCode ? (
            <div>
              <TextField
                required
                id="input-sessionID"
                label="Codice sessione"
                inputRef={sessionCode}
                fullWidth
                style={{ marginBottom: 10, marginTop: 40 }}
              />
              <Button
                onClick={handleLogin}
                variant="contained"
                color="primary"
                endIcon={<PlayCircleFilledIcon />}
                fullWidth
              >
                Inizia la sessione
              </Button>
            </div>
          ) : (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              mt={4}
            >
              <Typography variant="body1" style={{ textAlign: "right" }}>
                Oppure entra nella tua piattaforma personalizzata usando un
                account
              </Typography>
              <Button onClick={handleOpenModal}>Amico di Certrude</Button>
            </Grid>
          )}
        </Card>
      </Grid>

      <AmicoLoginModal open={openAmicoLogin} handleClose={handleCloseModal} />
    </Grid>
  );
}
