import {
  Box,
  Button,
  Checkbox,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { toast } from "react-toastify";
import { setCookie } from "../utils/manageCookies";

function AmicoLoginModal({ open, handleClose }) {
  const [email, setEmail] = useState();
  const [user, setUser] = useState();
  const [password, setPassword] = useState();
  const [checkedPrivacy, setCheckedPrivacy] = useState(false);

  const handleLogin = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login-tutor-amico`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            user,
            password,
          }),
        }
      );

      const data = await response.json();
      if (data.success) {
        //console.log(data.token);
        setCookie("token", data.token);
        setCookie("sessionID", data.sessionID);
        window.location.href = data.subdomain;
      } else {
        //return false
        toast.error("mail/codice non validi");
        console.log("ERRORE: mail/codice non validi");
      }
    } catch (e) {
      if (!e?.response) {
        toast.error("Il server non risponde. Prova più tardi");
        console.log(`${e}: Il server non risponde. Prova più tardi`);
        //return false;
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: 800,
          minWidth: 600,
          background: "#fff",
          border: "unset",
          borderRadius: 4,
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6" textAlign="center">
              {" "}
              Accedi alla tua piattaforma personalizzata{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Email"
              required
              InputLabelProps={{
                shrink: true,
              }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label="Amico username"
              required
              InputLabelProps={{
                shrink: true,
              }}
              value={user}
              onChange={(e) => {
                setUser(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="password"
              label="Password"
              required
              InputLabelProps={{
                shrink: true,
              }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-start"
            gap={2}
            alignItems="center"
          >
            <Checkbox
              checked={checkedPrivacy}
              onChange={() => {
                setCheckedPrivacy(!checkedPrivacy);
              }}
            />
            <p>
              Ho letto e accetto la{" "}
              <a
                target="_blank"
                style={{
                  fontWeight: 700,
                  textDecoration: "none",
                  color: "#222",
                }}
                href="/privacy_policy.html"
              >
                privacy policy
              </a>
            </p>
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={handleLogin}
              variant="contained"
              color="primary"
              endIcon={<PlayCircleFilledIcon />}
              fullWidth
              disabled={!checkedPrivacy || !email || !password || !user}
            >
              Inizia la sessione
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default AmicoLoginModal;
