import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Switch,
  Typography,
  styled,
} from "@mui/material";
import { forwardRef, useState } from "react";

import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SaveIcon from "@mui/icons-material/Save";

const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + JSON.stringify(value) + ";expires=" + expires.toUTCString();
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "dark" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function CookieSetting({
  title,
  description,
  checked,
  setChecked,
  disabled = false,
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Grid container justifyContent="space-between">
        <Typography variant="h6">{title}</Typography>
        <div>
          <Button
            size="small"
            endIcon={
              !open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
            }
            sx={{ mr: 2 }}
            onClick={() => setOpen(!open)}
          >
            {!open ? "Mostra" : "Nascondi"} descrizione
          </Button>
          <IOSSwitch
            checked={checked}
            disabled={disabled}
            onChange={() => {
              setChecked(!checked);
            }}
          />
        </div>
      </Grid>
      {open ? <p>{description}</p> : null}
    </>
  );
}

function CookieBanner({ initialOpen, initialPreference = false }) {
  const [open, setOpen] = useState(initialOpen);
  const [preferences, setPreferences] = useState(initialPreference);
  const [cookieNecessari, setCookieNecessari] = useState(true);
  const [cookieEsperienza, setCookieEsperienza] = useState(false);
  const [cookieMisurazione, setCookieMisurazione] = useState(false);
  const [cookieMarketing, setCookieMarketing] = useState(false);

  const handleClose = (experience, measurement, marketing) => {
    //localStorage.setItem("cookieAccepted", accepted);
    const cookie = setCookie(
      "cookieConsent",
      {
        necessary: true,
        experience,
        measurement,
        marketing,
      },
      365
    );
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Transition}
      keepMounted
      scroll="paper"
      //onClose={() => handleClose(undefined)}
      sx={{
        "& .MuiDialog-container": {
          alignItems: "flex-end",
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {!preferences
          ? "Questo sito web utilizza i cookie"
          : "Le tue preferenze relative alla privacy"}
      </DialogTitle>
      <DialogContent>
        {!preferences ? (
          <DialogContentText id="alert-dialog-slide-description">
            Utilizziamo cookie propri e di terze parti per finalità: tecniche
            (necessarie alla navigazione) e analitiche (per le statistiche).
            Puoi accettare tutti i cookie premendo il pulsante "Accetta", oppure
            cliccare su "Gestisci le tue preferenze" per operare una scelta
            analitica su specifici cookie. Al fine di revocare il consenso
            prestato e visualizzare le informazioni complete sul trattamento
            dati clicca qui:{" "}
            <a
              target="_blank"
              style={{ fontWeight: 700, textDecoration: "none", color: "#666" }}
              href="/privacy_policy.html"
            >
              Gestione cookie
            </a>
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-slide-description">
            Questo pannello ti permette di esprimere alcune preferenze relative
            al trattamento delle tue informazioni personali. Puoi rivedere e
            modificare le tue scelte in qualsiasi momento accedendo al presente
            pannello tramite l’apposito link. Per rifiutare il tuo consenso alle
            attività di trattamento descritte di seguito, disattiva i singoli
            comandi o utilizza il pulsante “Rifiuta tutto” e conferma di voler
            salvare le scelte effettuate.
            <Grid container my={2} gap={2} justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={<DoneIcon />}
                onClick={() => {
                  setCookieEsperienza(true);
                  setCookieMarketing(true);
                  setCookieMisurazione(true);
                  handleClose(true, true, true);
                }}
              >
                Accetta tutto
              </Button>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={() => {
                  setCookieEsperienza(false);
                  setCookieMarketing(false);
                  setCookieMisurazione(false);
                  handleClose(false, false, false);
                }}
              >
                Rifiuta tutto
              </Button>
            </Grid>
            <Divider sx={{ my: 2 }} />
            Le opzioni disponibili in questa sezione ti permettono di
            personalizzare le preferenze relative al consenso per qualsiasi
            tecnologia di tracciamento utilizzata per le finalità descritte di
            seguito. Per ottenere ulteriori informazioni in merito all'utilità e
            al funzionamento di tali strumenti di tracciamento, fai riferimento
            alla{" "}
            <a
              target="_blank"
              style={{ fontWeight: 700, textDecoration: "none", color: "#666" }}
              href="/privacy_policy.html"
            >
              privacy policy
            </a>
            . Tieni presente che il rifiuto del consenso per una finalità
            particolare può rendere le relative funzioni non disponibili.
            <CookieSetting
              title="Necessari"
              checked={cookieNecessari}
              setChecked={setCookieNecessari}
              disabled={true}
              description="Questi cookie sono strettamente necessari per
              garantire il funzionamento e la fornitura del servizio che ci hai
              richiesto e, pertanto, non richiedono il tuo consenso."
            />
            <CookieSetting
              title="Esperienza"
              checked={cookieEsperienza}
              setChecked={setCookieEsperienza}
              description="Questi strumenti di tracciamento ci permettono di 
              migliorare la qualità della tua esperienza utente e consentono 
              le interazioni con piattaforme, reti e contenuti esterni."
            />
            <CookieSetting
              title="Misurazione"
              checked={cookieMisurazione}
              setChecked={setCookieMisurazione}
              description="Questi strumenti di tracciamento ci permettono di misurare il
              traffico e analizzare il tuo comportamento per migliorare il nostro
              servizio."
            />
            <CookieSetting
              title="Marketing"
              checked={cookieMarketing}
              setChecked={setCookieMarketing}
              description="Questi strumenti di tracciamento ci permettono di 
              fornirti contenuti marketing o annunci personalizzati e 
              di misurarne la performance."
            />
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        {!preferences ? (
          <Grid container justifyContent="space-between" gap={2} px={10} mb={2}>
            <Button onClick={() => setPreferences(true)} variant="outlined">
              Gestisci le tue preferenze
            </Button>
            <Button
              onClick={() => {
                setCookieEsperienza(true);
                setCookieMarketing(true);
                setCookieMisurazione(true);
                handleClose(true, true, true);
                window.location.reload();
              }}
              variant="contained"
              sx={{ px: 8 }}
            >
              Accetta
            </Button>
          </Grid>
        ) : (
          <Grid container justifyContent="space-between">
            <IconButton
              onClick={() => {
                setPreferences(!preferences);
              }}
            >
              <NavigateBeforeIcon />
            </IconButton>
            <Button
              startIcon={<SaveIcon />}
              onClick={() => {
                handleClose(
                  cookieEsperienza,
                  cookieMisurazione,
                  cookieMarketing
                );
                window.location.reload();
              }}
            >
              Salva le tue preferenze
            </Button>
          </Grid>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default CookieBanner;
