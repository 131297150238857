import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import { AuthContextProvider } from "./context/AuthContextProvider";

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Accedi from "./pages/Accedi";
import Amici from "./pages/Amici";
import Contatti from "./pages/Contatti";

const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#0097A7",
    },
    secondary: {
      main: "#1DE9B6",
    },
    dark: {
      main: global.blueColor,
      hover: "#56789C",
    },
    info: {
      main: "#edf2f4",
    },
    white: {
      main: "#fff",
      hover: "#dbf0ff",
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div id="appContent">
        <AuthContextProvider>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" exact element={<Home />} />
              <Route path="/accedi" exact element={<Accedi />} />
              <Route path="/amici" exact element={<Amici />} />
              <Route path="/contatti" exact element={<Contatti />} />
            </Route>
          </Routes>
        </AuthContextProvider>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
