import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import logo from "../assets/light_logo.png";

const pages = ["Home", "Amici", "Contatti"];
const menu = [
  { label: "Home", to: "/" },
  { label: "Amici", to: "/amici" },
  { label: "Contatti", to: "/contatti" },
];

function ResponsiveAppBar() {
  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (to) => {
    setAnchorElNav(null);
    if (typeof to != "object") navigate(to);
  };

  return (
    <AppBar position="static">
      <Container
        maxWidth="xl"
        sx={{
          backgroundImage: `linear-gradient(#1DE9B6, #0097A7)`,
          pt: 2,
          pb: 2,
          pl: { xs: 2, md: 5 },
          pr: { xs: 2, md: 5 },
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ fontSize: 40 }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                key={"accedi"}
                onClick={() => handleCloseNavMenu("/accedi")}
              >
                <Typography
                  textAlign="center"
                  sx={{ fontWeight: 500, color: global.blueColor }}
                >
                  Avvia la sessione
                </Typography>
              </MenuItem>
              {menu.map((page) => (
                <MenuItem
                  key={page.label}
                  onClick={() => handleCloseNavMenu(page.to)}
                >
                  <Typography textAlign="center">{page.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box
            component="div"
            sx={{
              display: "flex",
              mr: { xs: 0, md: "2.5rem" },
              bgcolor: "#fff",
              width: 80,
              height: 80,
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <Box
              component="img"
              sx={{ width: 80, alignSelf: "center" }}
              src={logo}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              color: "#fff",
              gap: "2.5rem",
            }}
          >
            {menu.map((page) => (
              <Box key={page.label} onClick={() => handleCloseNavMenu(page.to)}>
                <Typography
                  variant="h5"
                  sx={{ cursor: "pointer" }}
                  align="center"
                >
                  {page.label}
                </Typography>
              </Box>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <Button
              color="white"
              variant="contained"
              style={{
                height: 60,
                color: global.blueColor,
                fontSize: "1rem",
                fontWeight: 700,
              }}
              href="/accedi"
            >
              Avvia la sessione
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
