import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

function Reveal({ children, direction = "up" }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  var variants;
  switch (direction) {
    case "up":
      variants = {
        hidden: { opacity: 0, y: 75 },
        visible: { opacity: 1, y: 0 },
      };
      break;
    case "down":
      variants = {
        hidden: { opacity: 0, y: -75 },
        visible: { opacity: 1, y: 0 },
      };
      break;
    case "left":
      variants = {
        hidden: { opacity: 0, x: 75 },
        visible: { opacity: 1, x: 0 },
      };
      break;
    case "right":
      variants = {
        hidden: { opacity: 0, x: -75 },
        visible: { opacity: 1, x: 0 },
      };
      break;
  }

  return (
    <div ref={ref} style={{ position: "relative" }}>
      <motion.div
        variants={variants}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.4 }}
      >
        {children}
      </motion.div>
    </div>
  );
}

export default Reveal;
