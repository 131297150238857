import React from "react";

import { Avatar, Box, Button, Grid, Typography, useTheme } from "@mui/material";

import CERtrude from "../assets/CERtrude.png";
import riunioneImg from "../assets/riunione.jpeg";
import Reveal from "../components/Reveal";
import CookieBanner from "../components/CookieBanner";
import { checkCookieExists } from "../utils/checkCookieExists";

export default function Home() {
  const theme = useTheme();
  return (
    <Box
      style={{
        backgroundColor: global.lightblueColor,
      }}
    >
      <CookieBanner initialOpen={!checkCookieExists("cookieConsent")} />
      <Grid
        component="section"
        container
        sx={{ bgcolor: "#fff", minHeight: "75vh", pb: 8, pt: 8 }}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          md={6}
          textAlign="center"
          display={{ xs: "block", md: "none" }}
        >
          <img
            src={CERtrude}
            style={{
              width: "50%",
              height: "auto",
              borderRadius: "50%",
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              flexDirection: "column",
              color: "#56789C",
              pl: { xs: 8, md: "16%" },
              pr: { xs: 8, md: 0 },
              gap: "2.5rem",
            }}
          >
            <Reveal direction="up">
              <p
                style={{
                  textAlign: "center",
                  fontSize: "2.5rem",
                  fontWeight: 700,
                  //maxWidth: "50%",
                  margin: 0,
                  paddingTop: "2rem",
                  color: global.blueColor,
                }}
              >
                Esplora Impara Partecipa
              </p>
            </Reveal>
            <p
              style={{
                textAlign: "left",
                fontSize: "2rem",
                fontWeight: 500,
                margin: 0,
              }}
            >
              Con CERtrude il futuro dell'Energia è nelle tue mani
            </p>

            <p
              style={{
                textAlign: "left",
                fontSize: "1.25rem",
                fontWeight: 300,
                margin: 0,
              }}
            >
              CERtrude è l'assistente virtuale che rende più semplice la
              creazione di una Comunità Energetica Rinnovabile
            </p>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                textAlign: { xs: "center", md: "right" },
                mt: "1.5rem",
              }}
            >
              <Reveal direction="up">
                <Button
                  variant="contained"
                  color="dark"
                  sx={{
                    fontSize: "1.5rem",
                    pl: 4,
                    pr: 4,
                    "&:hover": { backgroundColor: theme.palette.dark.hover },
                    color: "#fff",
                  }}
                  href="/accedi"
                >
                  Provala
                </Button>
              </Reveal>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          position="relative"
          right="-10%"
          display={{ xs: "none", md: "block" }}
        >
          <img
            src={CERtrude}
            style={{
              width: "80%",
              height: "auto",
              borderRadius: "50%",
            }}
          />
        </Grid>
      </Grid>

      {/* seconda section */}
      <Grid
        container
        component="section"
        sx={{
          bgcolor: global.lightblueColor,
          p: 8,
        }}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12} md={4} display="flex" justifyContent="center">
          <Reveal direction="right">
            <Avatar
              sx={{
                width: 220,
                height: 220,
                bgcolor: "#fff",
                color: global.lightblueColor,
              }}
            >
              <Typography variant="h3" align="center" fontSize={24} p={4}>
                CERtrude coinvolge la comunità
              </Typography>
            </Avatar>
          </Reveal>
        </Grid>
        <Grid item sx={{ color: "#fff" }} xs={12} md={8}>
          <Reveal direction="up">
            <Typography variant="h2" textAlign={{ xs: "center", md: "left" }}>
              Cosa fa CERtrude?
            </Typography>
          </Reveal>
          <ul style={{ fontSize: 24 }}>
            <li>Introduce al mondo delle rinnovabili e delle CER</li>
            <li>
              Guida all'interno di una CER virtuale per testarne funzionamento e
              benefici
            </li>
            <li>Fa scoprire, giocando, le strategie per migliorare!</li>
          </ul>
        </Grid>
      </Grid>

      {/* terza section */}
      <Grid
        container
        component="section"
        sx={{
          bgcolor: "#fff",
          p: 8,
        }}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12} md={8} sx={{ color: global.lightblueColor }}>
          <Reveal direction="up">
            <h1 className="big-title">Quali benefici offre CERtrude?</h1>
          </Reveal>
          <ul style={{ fontSize: 24 }}>
            <li>Informa, con contenuti chiari e approccio efficace</li>
            <li>Facilita aggregazione e coinvolgimento</li>
            <li>Aumenta la fiducia e riduce le barriere</li>
            <li>Supporta la progettazione della CER</li>
            <li>Fornisce strategie per ottimizzare e risparmiare</li>
          </ul>
        </Grid>
        <Grid item xs={12} md={4} display="flex" justifyContent="center">
          <Reveal direction="left">
            <Avatar
              sx={{
                width: 220,
                height: 220,
                bgcolor: global.lightblueColor,
                color: "#fff",
              }}
            >
              <Typography variant="h3" align="center" fontSize={22} p={4}>
                Comprendere le rinnovabili e le CER diventa divertente e
                semplice
              </Typography>
            </Avatar>
          </Reveal>
        </Grid>
      </Grid>

      {/* quarta section */}
      <Grid
        container
        component="section"
        sx={{
          bgcolor: global.lightblueColor,
          color: "#fff",
        }}
        justifyContent="center"
      >
        <Grid
          item
          sx={{
            bgcolor: global.blueColor,
            borderRadius: 10,
            p: { xs: 4, md: 8 },
            position: "relative",
            top: "-50px",
          }}
          xs={10}
          md={8}
          textAlign="center"
        >
          <Reveal direction="up">
            <p className="big-title">Chiunque può utilizzare CERtrude!</p>
          </Reveal>
          <Grid
            container
            sx={{ pl: { xs: 2, md: 6 }, pr: { xs: 2, md: 6 } }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={12} lg={6} display="flex" justifyContent="center">
              <Avatar
                src={riunioneImg}
                sx={{
                  width: 180,
                  height: 180,
                }}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <Typography sx={{ fontSize: 20, p: { xs: 2, md: 4 } }}>
                Basta riunirsi e, cellulari alla mano, lasciarsi guidare in
                pochi semplici passi verso la CER.
              </Typography>
            </Grid>
          </Grid>
          <Reveal direction="up">
            <Button
              variant="contained"
              color="white"
              fullWidth
              sx={{
                maxWidth: 300,
                height: 80,
                fontSize: 30,
                mt: 6,
                "&:hover": { backgroundColor: theme.palette.white.hover },
                color: global.blueColor,
              }}
              href="/accedi"
            >
              Provala
            </Button>
          </Reveal>
        </Grid>
      </Grid>
    </Box>
  );
}
