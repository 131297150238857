import { Avatar, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import logoLight from "../assets/light_logo.png";
import CookieBanner from "../components/CookieBanner";
import { checkCookieExists } from "../utils/checkCookieExists";

export default function Contatti() {
  return (
    <>
      <CookieBanner initialOpen={!checkCookieExists("cookieConsent")} />
      <Grid container spacing={4} p={6} mt={4}>
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h4" sx={{ color: global.blueColor }}>
            Vuoi entrare in contatto con noi?
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Typography variant="h5" sx={{ color: "#56789C" }}>
            Scrivici!
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid
          item
          width="fit-content"
          sx={{
            //width: 320,
            height: 120,
            borderRadius: 60,
            borderWidth: 1,
            borderColor: "#aaa",
            borderStyle: "solid",
          }}
          container
          justifyContent="flex-start"
          alignItems="center"
          py={2}
          px={3}
          gap={2}
        >
          <Avatar src={logoLight} sx={{ width: "110px", height: "auto" }} />
          <Divider orientation="vertical" />
          <div>
            <Typography variant="button" sx={{ color: global.blueColor }}>
              E-mail
            </Typography>
            <Typography variant="h6" sx={{ color: global.blueColor }}>
              certrude@likelight.it
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
